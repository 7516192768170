<template>
  <section title="">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col md="9">
                <h2>
                  Orçamentos
                </h2>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                md="9"
              />
              <b-col md="3" />
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label == 'status'">
                      <b-badge
                        :variant="nomeStatus(item).cor"
                      >
                        {{ nomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                    <slot v-if="column.label === 'Data'">
                      {{ item.created_at | moment("DD/MM/YY HH:mm") }}
                    </slot>
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        variant="outline-success"
                        class="btn-icon rounded-circle"
                        @click="carregarOrcamento(item)"
                      >
                        <feather-icon icon="ShoppingCartIcon" />
                      </b-button>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      msgDel: '',
      url: 'orders/budget',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: '#',
          name: 'id',
          orderable: false,
        },
        {
          label: 'cliente',
          name: 'customer.name',
          orderable: false,
        },
        {
          label: 'telefone',
          name: 'customer.phone',
          orderable: false,
        },
        {
          label: 'descontos',
          name: 'discount',
          orderable: false,
        },
        {
          label: 'total',
          name: 'totalOrder',
          orderable: false,
        },
        {
          label: 'status',
          orderable: false,
        },
        {
          label: 'Data',
          orderable: false,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
    }
  },
  async created() {
    this.$loading(true)
    this.BuscarDados(this.url)
  },
  methods: {
    nomeStatus(item) {
      if (item.statusId === 10) {
        return { cor: 'light-primary', msg: 'Orçamento' }
      }
      if (item.statusId === 11) {
        return { cor: 'light-warning', msg: 'Fiado' }
      } return { cor: 'danger', msg: 'ERROR' }
    },
    carregarOrcamento(item) {
      this.$confirm(
        {
          message: 'Deseja abrir este Orçamento ?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              localStorage.setItem('idPedido', JSON.stringify(item.id))
              this.$router.push({ name: 'app-pdv' })
            }
          },
        },
      )
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir ?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              this.$loading(true)
              await this.$http.delete(`orcamentodel/${item.id}`).then(resp => {
                this.msgDel = resp.data
              })
              if (this.msgDel.success) {
                this.$toast.success('Orçamento excluido com sucesso')
              } else {
                this.$toast.error('ERRO ao excluir orçamento, favor entrar em contato com o suporte', { timeout: 9000 })
              }
              this.reloadTable()
            }
          },
        },
      )
    },
  },

}

</script>

<style scoped>

</style>
